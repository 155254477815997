<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统数据管理</el-breadcrumb-item>
      <el-breadcrumb-item>系统机构信息管理</el-breadcrumb-item>
      <el-breadcrumb-item>系统机构信息详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <el-card>
      <mechanismDetailInfo></mechanismDetailInfo>
    </el-card>
  </div>
</template>

<script>
import mechanismDetailInfo from '@/components/school_detailComponents/mechanism_detail_info'
export default {
  data () {
    return {
    }
  },
  components: {
    mechanismDetailInfo
  },
  created () {
  },
  methods: {
    goBack () {
      this.$router.back()
    }
  }
}
</script>

<style>
</style>
