<template>
  <div>
    <el-form
      :model="editInfoForm"
      label-width="100px"
      ref="editInfoFormRef"
      :rules="editInfoRules"
      style="width: 100%"
    >
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item label="赛区" prop="districtId">
            <el-select
              placeholder="请选择"
              v-model="editInfoForm.districtId"
              @change="distChange"
              style="width: 100%"
            >
              <el-option
                v-for="item in dict_district"
                :key="item.id"
                :label="item.districtName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="机构省市区" prop="schoolArea">
            <el-cascader
              placeholder="请选择"
              v-model="editInfoForm.schoolArea"
              ref="schoolAreaRef"
              :options="areasData"
              style="width: 100%"
              :props="{
                expandTrigger: 'hover',
                label: 'name',
                value: 'areaId',
                children: 'areas',
              }"
              @change="provincesHandleChange()"
            ></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="机构名称" prop="name">
            <el-input
              v-model="editInfoForm.name"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item label="机构类型" prop="type">
            <el-select
              placeholder="请选择"
              v-model="editInfoForm.type"
              style="width: 100%"
            >
              <el-option
                v-for="item in dict_mechanismType"
                :key="item.dictCode"
                :label="item.dictLabel"
                :value="item.dictValue"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="机构地址" prop="address">
            <el-input
              v-model="editInfoForm.address"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="包含组别" prop="schoolLevels">
            <el-select
              placeholder="请选择"
              style="width: 100%"
              v-model="editInfoForm.schoolLevels"
              multiple
              @change="$forceUpdate()"
            >
              <el-option
                v-for="item in dict_group"
                :key="item.dictCode"
                :label="item.dictLabel"
                :value="item.dictValue"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item label="来源渠道" prop="sourceChannel">
            <el-select
              placeholder="请选择"
              style="width: 100%"
              allow-create
              filterable
              default-first-option
              v-model="editInfoForm.sourceChannel"
            >
              <el-option
                v-for="item in dict_sourceChannel"
                :key="item.dictCode"
                :label="item.dictLabel"
                :value="item.dictValue"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="备注" prop="remark">
            <el-input
              v-model="editInfoForm.remark"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="拓展人" prop="expander">
            <el-input
              v-model="editInfoForm.expander"
              placeholder="请输入拓展人"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 联系人信息 -->
      <contact-list
        style="margin-top: 15px"
        :data="editInfoForm.contactsList"
        @add="addContact"
        @edit="editContact"
        @remove="removeContact"
      ></contact-list>
    </el-form>
    <el-row type="flex" justify="center" style="margin-top: 20px">
      <el-col :span="4">
        <el-button type="primary" style="width: 100%" @click="saveClicked"
          >保存</el-button
        >
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { addSysInstitution, queryDistrictPage, getInstitutionlInfo, getAllAreaList } from '@/http/api'
import contactList from '@/components/bData/input/contactList/contactList.vue'
export default {
  components: { contactList },
  data () {
    return {
      dict_sourceChannel: this.$userInfo.getDataList(this, 't_source_channel').then(value => { this.dict_sourceChannel = value }),
      dict_group: this.$userInfo.dict_group(),
      dict_mechanismType: this.$userInfo.dict_mechanismType(),
      dict_countries: this.$userInfo.dict_countries(),
      dict_district: [],
      areasData: [],
      editInfoForm: JSON.parse(this.$route.query.editInfoForm),
      editInfoRules: {
        sourceChannel: [{ required: true, message: '请选择', trigger: 'change' }],
        schoolLevels: [{ required: true, message: '请选择', trigger: 'change' }],
        districtId: [{ required: true, message: '请选择', trigger: 'change' }],
        schoolArea: [{ required: true, message: '请选择', trigger: 'change' }],
        name: [{ required: true, message: '请输入', trigger: 'blur' }],
        type: [{ required: true, message: '请选择', trigger: 'change' }],
        address: [{ required: true, message: '请输入', trigger: 'blur' }],
        nationality: [{ required: true, message: '请选择', trigger: 'change' }],
        remark: [{ required: true, message: '请输入', trigger: 'blur' }]
      }
    }
  },
  created () {
    this.getDistrictList()
    this.getAllAreaList()
  },
  methods: {
    getAllAreaList () {
      getAllAreaList({}).then((res) => {
        this.areasData = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 获取赛区筛选数据
    getDistrictList () {
      queryDistrictPage({ pageNum: 1, pageSize: 10000 }).then((res) => {
        this.dict_district = res.data.list
        this.getSchoolContactsData()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    getSchoolContactsData () {
      if (this.editInfoForm.id) {
        getInstitutionlInfo(this.editInfoForm.id).then(res => {
          console.log('getInstitutionlInfo', res)
          this.editInfoForm.contactsList = res.data.contactsList
          this.editInfoForm.schoolLevels = this.editInfoForm.schoolLevel.split(',')
          this.$forceUpdate()
        })
      } else {
        this.editInfoForm.contactsList = []
      }
    },
    // 保存机构信息
    saveClicked () {
      console.log('editInfoForm', this.editInfoForm)
      this.$refs.editInfoFormRef.validate(async valid => {
        if (!valid) return
        this.editInfoForm.schoolLevel = this.editInfoForm.schoolLevels.join(',')
        addSysInstitution(this.editInfoForm).then((res) => {
          this.$message.success('操作成功')
          this.goBack()
        }).catch((err) => {
          console.log('err', err)
        })
      })
    },
    // 机构省市区选择
    provincesHandleChange (value) {
      console.log('value', value)
      this.editInfoForm.provinceCode = this.editInfoForm.schoolArea[0]
      this.editInfoForm.cityCode = this.editInfoForm.schoolArea[1]
      this.editInfoForm.areaCode = this.editInfoForm.schoolArea[2]
      const deliveryAreaName = this.$refs.schoolAreaRef.getCheckedNodes()[0].pathLabels.join('/')
      const areaList = deliveryAreaName.split('/')
      this.editInfoForm.province = areaList[0]
      this.editInfoForm.city = areaList[1]
      this.editInfoForm.area = areaList[2]
    },
    goBack () {
      this.$router.back()
    },
    distChange (e) {
      this.editInfoForm.districtName = this.dict_district.find(item => item.id === e).districtName
    },
    nationChange (e) {
      this.editInfoForm.nationalityCode = this.dict_countries.find(item => item.label === e).value
    },
    // 联系人信息相关
    addContact (addContactForm) {
      console.log()
      this.editInfoForm.contactsList.push({ ...addContactForm })
    },
    editContact (index, editContactForm) {
      this.editInfoForm.contactsList.splice(index, 1, { ...editContactForm })
    },
    removeContact (index) {
      // if (this.editInfoForm.deleteIds.length > 0) {
      //   this.editInfoForm.deleteIds += ',' + this.editInfoForm.contactsList[index].id
      // } else {
      //   this.editInfoForm.deleteIds = this.editInfoForm.contactsList[index].id + ''
      // }
      this.editInfoForm.contactsList.splice(index, 1)
    }
  }
}
</script>

  <style>
</style>
